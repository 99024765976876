<template>
  <div id="Login">
    <Loader id="page-loader" v-if="loading"></Loader>
    <div class="row">
      <div class="col-xl-4 col-lg-3 col-md-3 col-sm-2 col-12"></div>
      <div class="col-xl-4 col-lg-6 col-md-6 col-sm-8 col-12">
        <div class="logo" style="position: relative;">
          <img :src="require('@/assets/images/Logo_Original@2x.png')" alt="" />
          <div
            class="current-flag"
            style="float: right;cursor: pointer;"
            v-if="lang == 'en'"
            @click="showLang"
          >
            <font-awesome-icon
              class="chevron"
              :icon="['fas', 'chevron-down']"
            />
            {{ $t("Teller.English") }}
          </div>
          <div
            class="current-flag"
            style="float: right;cursor: pointer;"
            v-if="lang == 'ar'"
            @click="showLang"
          >
            <font-awesome-icon
              class="chevron"
              :icon="['fas', 'chevron-down']"
            />
            {{ $t("Teller.Arabic") }}
          </div>
          <div
            class="current-flag"
            style="float: right;cursor: pointer;"
            v-if="lang == 'fr'"
            @click="showLang"
          >
            <font-awesome-icon
              class="chevron"
              :icon="['fas', 'chevron-down']"
            />
            {{ $t("Teller.French") }}
          </div>
          <div class="all-lang">
            <div class="" @click="changeLanguage('en')">
              {{ $t("Teller.English") }}
            </div>
            <div class="" @click="changeLanguage('ar')">
              {{ $t("Teller.Arabic") }}
            </div>
            <div class="" @click="changeLanguage('fr')">
              {{ $t("Teller.French") }}
            </div>
          </div>
        </div>
        <!-- <CountrySelector :selectable="true"></CountrySelector> -->
        <div class="login">
          <h4>{{ $t("Teller.Login") }}</h4>
          <span class="error" v-if="show_error.status">
            <img :src="require('@/assets/images/Error-512.webp')" alt="" />
            {{ show_error.message }}
          </span>
          <input
            type="email"
            class="input email"
            :placeholder="$t('Teller.EmailEnter')"
            v-model="email"
          />
          <input
            type="password"
            class="input password"
            placeholder="•••••••••••••"
            required
            v-model="password"
          />
          <button v-if="login_button" class="btn" @click="login">
            {{ $t("Teller.LoginButton") }}
          </button>
          <button v-else class="btn" @click="login">
            <font-awesome-icon class="spinner" :icon="['fas', 'spinner']" />
          </button>
        </div>

        <div class="types">
          <div
            :class="
              selected_type == 1 ? 'type business active' : 'type business'
            "
            @click="selected_type = 1"
          >
            <span>{{ $t("Teller.Collaborater") }}</span>
          </div>
          <div
            :class="
              selected_type == 2
                ? 'type organization active'
                : 'type organization'
            "
            @click="selected_type = 2"
          >
            <span>{{ $t("Teller.Manager") }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "@vue/composition-api";
import $ from "jquery";
import { userService, managerService } from "../../_services";
import Loader from "../../components/loader/Loader.vue";
// import CountrySelector from '../../components/country/CountrySelector'

export default defineComponent({
  data: () => ({
    login_button: true,
    email: "",
    password: "",
    show_error: {
      status: false,
      message: "",
    },
    selected_type: 1,
    server: localStorage.getItem("server")
      ? localStorage.getItem("server")
      : "tn",
    loading: false,
    lang: "",
  }),
  components: {
    Loader,
    //   CountrySelector
  },
  methods: {
    changeLanguage(locale) {
      if (localStorage.getItem("Language") != locale) {
        this.$i18n.locale = locale;
        localStorage.setItem("Language", locale);
        location.reload(true);
      } else {
        $(".current-flag").toggle();
        $(".all-lang").toggle();
      }
    },
    getCurrentLanguage() {
      const locale = this.$i18n.locale;
      if (locale == "en") {
        this.lang = "en";
      } else if (locale == "fr") {
        this.lang = "fr";
      } else {
        this.lang = "ar";
      }
    },
    selectServer(sv) {
      if (localStorage.getItem("server") != sv) {
        this.loading = true;
        localStorage.setItem("server", sv);
        window.location.reload();
      } else {
        $(".current-flag").toggle();
        $(".all-lang").toggle();
      }
    },
    updateHeights() {
      $("#Login").css(
        "margin-top",
        (($(window).height() - $("#Login").height()) / 2) * 0.8
      );
    },
    login() {
      const self = this;
      if (this.login_button) {
        if (this.selected_type == 1) {
          this.login_button = false;
          userService
            .loginNoCaptcha(this.email, this.password)
            .then(function(data) {
              console.log(data);
              location.reload(true);
            })
            .catch(function(data) {
              self.show_error.status = true;
              self.show_error.message = data;
            })
            .finally(function() {
              self.login_button = true;
            });
        } else {
          this.login_button = false;
          managerService
            .login(this.email, this.password)
            .then(function(data) {
              console.log(data);
              self.$router.push("/manager_waitinglist");
            })
            .catch(function(data) {
              self.show_error.status = true;
              self.show_error.message = data;
            })
            .finally(function() {
              self.login_button = true;
            });
        }
      }
    },
    showLang() {
      $(".current-flag").hide();
      $(".all-lang").show();
    },
  },
  mounted() {
    this.updateHeights();
    if (localStorage.getItem("user")) {
      if (!JSON.parse(localStorage.getItem("user")).organization_id) {
        this.$router.push("/waitinglist");
      } else {
        this.$router.push("/manager_waitinglist");
      }
    }

    $(".current-flag").on("click", function() {
      $(".current-flag").hide();
      $(".all-lang").show();
    });

    // $('.all-flags').on('mouseout', function(){
    //     $('.all-flags').hide();
    //     $('.current-flag').show();
    // })
    if (localStorage.getItem("host") != null) {
      if (
        confirm(
          "You changed the original host. If you want to return to the default one click Ok."
        )
      ) {
        localStorage.removeItem("host");
        localStorage.removeItem("ws");
      }
    }
    this.getCurrentLanguage();
  },
  created() {
    $(window).resize(function() {
      $("#Login").css(
        "margin-top",
        (($(window).height() - $("#Login").height()) / 2) * 0.8
      );
    });
  },
});
</script>

<style scoped>
.types {
  text-align: center !important;
  width: 100%;
}
.type {
  text-align: center;
  display: inline-block;
  border: 1px solid #29a1d3;
  color: #29a1d3;
  padding: 5px 10px;
  border-radius: 5px;
  transition: 0.4s;
  cursor: pointer;
}
.business {
  margin-right: 5px;
}
.organization {
  margin-left: 5px;
}
.types .active {
  background: #29a1d3;
  color: #fff;
}

.all-flags {
  display: none;
  transition: 0.5s;
  float: right;
  text-align: right;
  display: absolute;
  background: #fff;
  border-radius: 2px;
  z-index: 9999999;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  cursor: pointer;
}
.all-flags div {
  z-index: 999999999999;
}
.all-flags .flag {
  display: block;
  z-index: 99999999;
}

.all-lang {
  display: none;
  transition: 0.5s;
  float: right;
  text-align: right;
  display: absolute;
  background: #fff;
  border-radius: 2px;
  z-index: 999999999;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  cursor: pointer;
}
.chevron {
  position: absolute;
  margin-top: 12px;
  right: 50px;
  margin-right: 10px;
  font-size: 12px;
}

.current-flag {
  margin-top: 10px;
}
</style>
